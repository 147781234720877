<template>
    <footer id="bottom" class="footer-wrap position-sticky player-ready" :style="showPlayer ? 'visibility: visible' : 'visibility: hidden; position: absolute !important;'">
        <div class="container-fluid">
            <div class="stickyfooter">
                <div class="vbry-player">
                    <div class="d-flex justify-content-between">
                        <div class="vbry-audio d-flex my-auto flex-grow-1">
                            <div class="vbry-controls d-flex my-auto">
                                <div class="my-auto">
                                    <a href="#" :class="`prev mx-1 mx-md-2 ${loadedSongs != (songs ? songs.length : 0) ? 'disabled' : ''}`" @click.prevent="previous">
                                        <ion-icon name="play-skip-back-sharp"></ion-icon>
                                        <span class="sr-only">Back</span>
                                    </a>
                                </div>
                                <div class="my-auto">
                                    <a href="#" :class="`pause-play mx-lg-2 ${isPlaying ? 'playing' : ''}`" id="mainPLayer" @click.prevent="playSong">
                                        <div class="pie-timer">
                                            <div class="timer first-half">
                                                <div class="left-mask"></div>
                                                <div class="right-half"></div>
                                                <div class="left-half spinner"></div>
                                            </div>
                                            <div class="disk"></div>
                                        </div>
                                        <ion-icon name="play-sharp"></ion-icon>
                                        <ion-icon name="pause-sharp"></ion-icon>
                                        <span class="sr-only">Play/Pause</span>
                                    </a>
                                </div>
                                <div class="my-auto">
                                    <a href="#" :class="`next mx-1 mx-md-2 ${loadedSongs != (songs ? songs.length : 0) ? 'disabled' : ''}`" @click.prevent="next">
                                        <ion-icon name="play-skip-forward-sharp"></ion-icon>
                                        <span class="sr-only">forward</span>
                                    </a>
                                </div>
                            </div>
                            <div class="audio-meta my-auto mx-sm-3 mx-1">
                                <span class="title">{{song ? song.title : ''}}</span>
                                <span class="sub">{{song && song.mood ? getMood(song.mood) : ''}}</span>
                            </div>
                            <div class="track-preview my-auto mx-3 d-flex flex-grow-1">
                                <div class="mx-auto">
                                    <a id="closePreview" class="py-2 mb-4" @click="closeFullscreenPlayer()">
                                        <span class="sr-only">Close</span>
                                        <chevron-down-icon size="1.5x" class="custom-class"></chevron-down-icon>
                                    </a>
                                </div>
                                <div class="vinyl-grp-wrap playing mx-auto w-100" id="vinyl_record_mobile">
                                    <div class="vinyl-grp">
                                        <div class="vinyl-img">
                                            <img src="/images/disk.png" width="320" height="320" alt="album cover" loading="lazy" draggable="false">
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-grow-1 waveform-wrap">
                                    <div class="vbry-playback flex-grow-1">
                                        <div class="d-flex">
                                            <div class="timestamp my-auto px-2">
                                                <span id="seekTime"></span>
                                            </div>
                                            <div class="vbry-wave flex-grow-1 px-2 text-center my-lg-auto my-2">
                                                <!-- main player -->
<!--                                                <div id="player_waveform" class="waveform"></div>-->
                                              <div id="minimap"></div>
<!--                                              {{song ? JSON.stringify(song.wavesurfer.container.outerHTML) : ''}}-->
<!--                                              <div v-html="song ? JSON.stringify(song.wavesurfer.container.outerHTML) : ''"></div>-->
                                                <!-- other waves in playlist -->
                                                <div class="d-none" id="waveform_sample1"></div>
                                                <div class="d-none" id="waveform_sample"></div>
                                                <div class="d-none" id="waveform_sample2"></div>
                                                <div class="d-none" id="waveform_sample5"></div>
                                                <div class="d-none" id="waveform_sample3"></div>
                                                <div class="d-none" id="waveform_sample4"></div>
                                            </div>
                                            <div class="length my-auto px-2">
                                                <span id="endTime">0:00</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vbry-controls d-flex my-2 mx-auto justify-content-center">
                                        <div class="my-auto">
                                            <a href="#" :class="`prev mx-1 mx-md-2 ${loadedSongs != (songs ? songs.length : 0) ? 'disabled' : ''}`" @click.prevent="previous">
                                                <ion-icon name="play-skip-back-sharp"></ion-icon>
                                                <span class="sr-only">Back</span>
                                            </a>
                                        </div>
                                        <div class="my-auto">
                                            <a href="#" :class="`pause-play mx-lg-2 ${isPlaying ? 'playing' : ''}`" id="mainPLayer" @click.prevent="playSong">
                                                <div class="pie-timer">
                                                    <div class="timer first-half">
                                                        <div class="left-mask"></div>
                                                        <div class="right-half"></div>
                                                        <div class="left-half spinner"></div>
                                                    </div>
                                                    <div class="disk"></div>
                                                </div>
                                                <ion-icon name="play-sharp"></ion-icon>
                                                <ion-icon name="pause-sharp"></ion-icon>
                                                <span class="sr-only">Play/Pause</span>
                                            </a>
                                        </div>
                                        <div class="my-auto">
                                            <a href="#" :class="`next mx-1 mx-md-2 ${loadedSongs != (songs ? songs.length : 0) ? 'disabled' : ''}`" @click.prevent="next">
                                                <ion-icon name="play-skip-forward-sharp"></ion-icon>
                                                <span class="sr-only">forward</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="audio-meta py-2 mx-auto text-center">
                                        <span class="title">{{song ? song.title : ''}}</span>
                                        <span class="sub">{{song && song.mood ? getMood(song.mood) : ''}}</span>
                                    </div>
                                    <div class="vrby-vol my-auto d-flex px-3 py-2">
                                        <div class="vrby-mute my-auto mx-1 mx-lg-2">
                                            <a href="#" @click.prevent="mute" :class="`volume ${getVolumeLevel()}`" id="volumeCtr">
                                                <volume-icon size="1.2x" class="custom-class"></volume-icon>
                                                <volume-1-icon size="1.2x" class="custom-class"></volume-1-icon>
                                                <volume-2-icon size="1.2x" class="custom-class"></volume-2-icon>
                                                <volume-x-icon size="1.2x" class="custom-class"></volume-x-icon>
                                                <span class="sr-only" >Mute</span>
                                            </a>
                                        </div>
                                        <div class="volslider my-auto mx-1 mx-lg-2 px-4 px-lg-3">
<!--                                            <input id="volume" type="range" min="0" max="1" value="1" step="0.1">-->
                                            <input id="volume" class="slider" type="range" min="0" max="1" :value="currentVolume" step="0.1">

                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <a v-if="song" @click="downloadSong"  class="download-mobile btn-yellow my-2">
                                        <span>Download</span>
                                        <download-icon size="1x" class="custom-class"></download-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="dl my-auto d-none d-lg-flex mx-1 mx-md-2">
                            <a v-if="song" @click.prevent="downloadSong" class="dl-link">
                                <download-icon size=".6x" class="custom-class"></download-icon>
                                <span class="sr-only">Download</span>
                            </a>
                        </div>
                        <div class="cta my-auto d-flex d-lg-none mx-1">
                            <a class="mobile-more" id="mobilepreview" @click="showFullscreenPlayer()">
                                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { ChevronDownIcon } from 'vue-feather-icons'
import { DownloadIcon } from 'vue-feather-icons'
import { VolumeIcon } from 'vue-feather-icons'
import { Volume1Icon } from 'vue-feather-icons'
import { Volume2Icon } from 'vue-feather-icons'
import { VolumeXIcon } from 'vue-feather-icons'
import { MoreVerticalIcon } from 'vue-feather-icons'
import {mapGetters} from "vuex";
import MinimapPlugin from "../../../public/js/wavesurfer.minimap";

export default {
  name:'StickyPlayer',
  props:['showPlayer'],
  components: {
    ChevronDownIcon,
    DownloadIcon,
    VolumeIcon,
    Volume1Icon,
    Volume2Icon,
    VolumeXIcon,
    MoreVerticalIcon
  },
  data(){
    return {
      previousVolume:1,
    }
  },
  created() {
    if(this.song && this.song.wavesurfer){
      this.song.wavesurfer.stop();
      this.song.wavesurfer.destroyAllPlugins();
    }
  },
  methods : {
    playSong(){
      if(this.song && this.song.wavesurfer){
        console.log(this.song.wavesurfer);

        let song = this.song;
        let self = this;
        this.songs.map(s => {
          if(s.id !== song.id){
            s.wavesurfer.destroyAllPlugins();
            s.wavesurfer.pause();
          }
        });
        document.getElementById('vinyl_record').classList.add("animate-paused");
        document.getElementById('vinyl_record_mobile').classList.add("animate-paused");

        song.wavesurfer.playPause();
        if(song.wavesurfer.isPlaying()){
          console.log('ppppppppppppppppppppp');
          // vinyl record animation
          document.getElementById('vinyl_record').classList.add("playing");
          document.getElementById('vinyl_record').classList.remove("animate-paused");

          document.getElementById('vinyl_record_mobile').classList.add("playing");
          document.getElementById('vinyl_record_mobile').classList.remove("animate-paused");


          //minimap
          song.wavesurfer.destroyAllPlugins();
          song.wavesurfer.addPlugin(
              // Minimap
              MinimapPlugin.create({
                container:minimap,
                barWidth: 2,
                barHeight: 1.6,
                barGap: null,
                waveColor: "#ebebeb",
                progressColor: "#ffd300",
                cursorColor: "#fff0",
                height: 34,
                pixelRatio: 1,
                responsive: true,
              })
          ).initPlugin('minimap');
          //end minimap

          //set volume
          song.wavesurfer.setVolume(this.currentVolume);
          document.querySelector('#volume').value = song.wavesurfer.getVolume();
          let volumeInput = document.querySelector('#volume');
          let onChangeVolume = function (e) {
            song.wavesurfer.setVolume(e.target.value);
            self.$store.commit("player/SET_VOLUME" , song.wavesurfer.getVolume());
          };
          volumeInput.addEventListener('input', onChangeVolume);
          volumeInput.addEventListener('change', onChangeVolume);

          //add on finish song
          song.wavesurfer.on('finish', function () {
            //wavesurfer.params.container.style.opacity = 0.9;
            self.next();
          });

          //set timer
          this.moveTime(song.wavesurfer);
          this.$store.commit("player/SET_IS_PLAYING" , true);
        }else{
          this.$store.commit("player/SET_IS_PLAYING" , false);
        }
        this.$store.commit("player/SET_SELECTED_SONG" , song);

      }
    },
    moveTime(wavesurfer){
      let mainPlayer = document.getElementById("mainPLayer");
      let self = this;
      wavesurfer.on('audioprocess', function() {
        if(wavesurfer.isPlaying()) {
          let totalTime = wavesurfer.getDuration();
          let currentTime = wavesurfer.getCurrentTime();
          let remainingTime = totalTime - currentTime;
          let spinnerAngle = (currentTime/totalTime)*360;

          mainPlayer.querySelector(".spinner").style.transform = "rotate("+spinnerAngle+"deg)";
          if(spinnerAngle > 180){
            mainPlayer.querySelector(".timer").classList.remove("first-half");
          }else{
            mainPlayer.querySelector(".timer").classList.add("first-half");
          }

          document.getElementById('seekTime').textContent = self.timeFormat(currentTime);
          document.getElementById('endTime').textContent = self.timeFormat(remainingTime);
        }
      });
    },
    timeFormat(flt){
      var min = parseInt(flt/60),
          sec = parseInt(flt%60) < 10 ? "0"+parseInt(flt%60) : parseInt(flt%60).toString();
      return min+":"+sec;
    },
    next(){
      if(this.loadedSongs == this.songs.length){
        let songIndex = null;
        if(this.songs){
          let length = this.songs.length;
          for (let ctr = 0; ctr < length; ctr++){
            if(this.songs[ctr].id == this.song.id && (length-1) != ctr){
              songIndex = ctr + 1;
              break;
            }
          }
          if(songIndex){
            this.$store.commit("player/SET_SELECTED_SONG" , this.songs[songIndex]);
            this.playSong();
          }
        }
      }
    },
    previous(){
      if(this.loadedSongs == this.songs.length){
        let songIndex = null;
        if(this.songs){
          for (let ctr = 0; ctr < this.songs.length; ctr++){
            if(this.songs[ctr].id == this.song.id){
              if(ctr != 0){
                songIndex = ctr - 1;
              }
              break;
            }
          }
          if(songIndex != null){
            this.$store.commit("player/SET_SELECTED_SONG" , this.songs[songIndex]);
            this.playSong();
          }
        }
      }
    },
    getMood(mood){
      let songMood = "";
        if(mood && mood.length > 0){
          let moods = [];
          mood.map(m => {
            moods.push(m.categoryName);
          });
          songMood = moods.join(", ");
        }
      return songMood;
    },
    mute(){
      let self = this;
      if(this.song && this.song.wavesurfer){
        if(this.song.wavesurfer.getVolume() > 0){
          this.previousVolume = this.song.wavesurfer.getVolume();
          this.song.wavesurfer.setVolume(0);
          this.$store.commit("player/SET_VOLUME" , 0);

        }else{
          this.song.wavesurfer.setVolume(this.previousVolume);
          this.$store.commit("player/SET_VOLUME" , this.previousVolume);
        }

        document.querySelector('#volume').value = this.song.wavesurfer.getVolume();
        var volumeInput = document.querySelector('#volume');
        var onChangeVolume = function (e) {
          self.song.wavesurfer.setVolume(e.target.value);
          self.$store.commit("player/SET_VOLUME" , self.song.wavesurfer.getVolume());
        };
        volumeInput.addEventListener('input', onChangeVolume);
        volumeInput.addEventListener('change', onChangeVolume);

      }
    },
    showFullscreenPlayer() {
				document.body.classList.toggle("prevent-scroll");
				document.querySelector(".track-preview").classList.toggle("active");
				document.querySelector(".footer-wrap").classList.toggle("showing-preview");
    },
    closeFullscreenPlayer() {
      // transition close
			document.querySelector(".track-preview").classList.add("closing");
      
			setTimeout(function(){ //wait for closing animation 
					document.body.classList.remove("prevent-scroll");
					document.querySelector(".footer-wrap").classList.remove("showing-preview");
					document.querySelector(".track-preview").classList.remove("active");
					document.querySelector(".track-preview").classList.remove("closing");
			}, 400);
    },
    getVolumeLevel(){
      let level = "off";
      if(this.currentVolume > 0.5){
        level = "high";
      }else if(this.currentVolume > 0 && this.currentVolume <= 0.5){
        level = "low";
      }else if(this.currentVolume == 0){
        level = "low mute";
      }
      return level;
    },
    downloadSong(){
      this.closeFullscreenPlayer();
      if(this.song){
        if(this.song.wavesurfer && this.song.wavesurfer.isPlaying()){
          this.song.wavesurfer.pause();
        }
        this.$router.push({ path: "/download/" + this.song.slug})
      }

    }
  },
  computed : {
    ...mapGetters({
      song : 'player/getSelectedSong',
      songs : 'player/getSongs',
      currentVolume : 'player/getVolume',
      isPlaying:'player/getIsPlaying',
      loadedSongs:'player/getLoadedSongs',
    }),
  }
}
</script>
