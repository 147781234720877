<template>
    <div :class="`${loading ? 'loading' : ''}`">
      <div class="loader">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
        <div class="bar4"></div>
        <div class="bar5"></div>
        <div class="bar6"></div>
      </div>

        <section id="main" class="main-viewport">
            <div class="subpage-banner py-4">
                <div class="container-fluid">
                    <router-link to="/homemusic" class="back d-inline-block mb-4" id="back_btn">
                        <arrow-left-icon size="1.5x" class="custom-class"></arrow-left-icon>
                        Back
                    </router-link>
                </div>
                <div class="container">
                    <div class="vbry-player">
                        <div class="d-flex justify-content-between">
                            <div class="vbry-audio d-flex my-auto flex-grow-1">
                                <div class="vbry-controls d-flex my-auto">
                                    <div class="my-auto">
                                        <a href="#" class="pause-play mx-1 mx-md-2" id="mainPLayer">
                                            <div class="pie-timer">
                                                <div class="timer first-half">
                                                    <div class="left-mask"></div>
                                                    <div class="right-half"></div>
                                                    <div class="left-half spinner"></div>
                                                </div>
                                                <div class="disk"></div>
                                            </div>
                                            <ion-icon name="play-sharp"></ion-icon>
                                            <ion-icon name="pause-sharp"></ion-icon>
                                            <span class="sr-only">Play/Pause</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="my-auto mx-3 d-flex flex-grow-1">
                                    <div class="d-flex flex-grow-1 waveform-wrap">
                                        <div class="vbry-playback flex-grow-1">
                                            <div class="d-flex">
                                                <div class="timestamp my-auto px-2">
                                                    <span id="seekTime">0:00</span>
                                                </div>
                                                <div class="vbry-wave flex-grow-1 px-2 text-center my-lg-auto my-2">
                                                    <!-- main player -->
                                                    <div id="waveform"></div>
                                                </div>
                                                <div class="length my-auto px-2">
                                                    <span id="endTime">0:00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="audio-meta">
                        <span class="title">{{ music.title }}</span>
                    </div>
                </div>
            </div>
            <div class="subpage-content content-wrap">
                <!-- Content start -->
                <div class="dl-info">
                    <div class="container">
                        <div class="d-flex justify-content-center py-4 flex-wrap">
                            <div class="my-auto py-2">
                                <span class="tempo">Tempo: {{ music.tempo }} BPM</span>
                            </div>
                            <div class="my-auto ml-md-auto py-2">
                                <div class="trim d-flex justify-content-center flex-wrap">
                                    <div class="my-auto pb-2 pr-2">
                                        <span>
                                            Download
                                            <arrow-right-icon size="1.5x" class="custom-class"></arrow-right-icon>
                                        </span>
                                    </div>
                                    <div class="d-flex my-auto pb-2">
                                        <div class="my-auto mx-2">
                                            <a @click="showModal('short_track')" class="btn yellow-btn modal-btn" id="direct_download">
                                                0:00
                                            </a>
                                        </div>
                                        <div class="my-auto mx-2">
                                            <a @click="showModal('long_track')" class="btn grey-btn modal-btn" id="direct_download_long">
                                                0:00
                                            </a>
                                        </div>
                                        <div class="my-auto mx-2">
                                            <span>Select Length</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container py-4">
                    <div class="row no-gutters">
                        <div class="col-12 pt-3">
                            <p><strong>Tags</strong></p>
                            <div class="row flex-wrap tag-cloud">
                                <div class="col-auto" v-for="(tag, i) in music.tag" :key="i">
                                    <a href="#" class="btn mb-3">{{tag.tagName}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="license-lbl py-2 px-4 my-4 d-inline-block">
                                <strong class="px-2 d-inline-block">LICENSE</strong>
                            </div>
                            <div class="text-center">
                                <h4 class="fw-700">FREE License with attribution</h4>
                                <div class="copy-attr my-4">
                                    <input
                                        type="text"
                                        placeholder="Music by Viberary.com"
                                        v-model="attrLink"
                                        readonly
                                        class="attr-link"
                                    />
                                    <button class="copy-btn"
                                        v-clipboard:copy="attrLink"
                                        v-clipboard:success="onCopy"
                                        v-clipboard:error="onError"
                                    >
                                        {{ this.copyText }}
                                        <copy-icon size="1.5x" class="custom-class"></copy-icon>
                                    </button>
                                </div>
                                <p class="note mb-4 pb-4">Copy and paste this on your project description or credits section</p>
                            </div>
                            <div class="white-box p-4">
                                <div class="py-2 px-4">
                                  <p v-html="content"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Content end -->
            </div>
            <SubFooter />
        </section>

        <div class="modal" :class="{ hidden: isModalHidden }">
            <div class="container">
                <div class="body">
                    <div class="text-right">
                        <a @click="closeModal()" class="close-modal mb-4">
                            <x-icon size="1.5x" class="custom-class"></x-icon>
                            <span class="sr-only">Close</span>
                        </a>
                    </div>
                    <div class="text-center">
                        <div class="px-4">
                            <h4 class="mb-4">
                                GREAT! You finally found the right track for you.
                            </h4>
                            <p class="mb-4">Before you proceed to download, we are just a bit curious where you'll use it.</p>
                            <div class="px-2 px-lg-4 text-left mx-auto" style="max-width: 650px;">
                                    <div class="mb-4">
                                        <label for="personal_use" class="radio" @click="forPersonal()">
                                            <div class="item-radio">
                                                <input id="personal_use" type="radio" value="For Personal Use" v-model="use">
                                                <svg width="20px" height="20px" viewBox="0 0 20 20">
                                                    <circle cx="10" cy="10" r="9"></circle>
                                                    <path d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" class="inner"></path>
                                                    <path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" class="outer"></path>
                                                </svg>
                                            </div>
                                            <div class="radio-label pl-2">
                                                Personal use (e.g. Vlog, slideshow, School project)
                                            </div>
                                        </label>
                                    </div>
                                    <div class="mb-4">
                                        <label for="commercial_use" class="radio" @click="forCommercial()">
                                            <div class="item-radio">
                                                <input id="commercial_use" type="radio" value="For Commercial Use" v-model="use">
                                                <svg width="20px" height="20px" viewBox="0 0 20 20">
                                                    <circle cx="10" cy="10" r="9"></circle>
                                                    <path d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" class="inner"></path>
                                                    <path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" class="outer"></path>
                                                </svg>
                                            </div>
                                            <div class="radio-label pl-2">
                                                Commercial use (e.g. Advertisement, Brand explainer video)
                                            </div>
                                        </label>
                                    </div>
                                    <div class="px-md-4 pt-1" v-if="this.trackType === 'long_track'">
                                        <div class="text-center":class="{ dnone: hideDisabledDownlad }">
                                            <button class="btn yellow-btn submit px-4 mb-2 disabled" type="submit" disabled>
                                              DOWNLOAD
                                            </button>
<!--                                          <router-link to="/license-terms">License Terms</router-link>-->
                                            <p class="note mb-4 py-2">By clicking Download, you agree to our <a href="/license-terms" target="_blank" class="uline">License Terms</a>.</p>
                                        </div>

                                        <div class="modal-form" :class="{ dnone: forPersonalUse }">
                                            <div class="mb-3">
                                                <input type="text" :class="`${validation.fullName ? 'input-required' : ''}`" name="full_name" placeholder="Enter Full Name" v-model="corporateData.fullName">
                                            </div>
                                            <div class="mb-3">
                                                <input type="text" :class="`${validation.company ? 'input-required' : ''}`" name="company_name" placeholder="Enter Company Name" v-model="corporateData.company">
                                            </div>
                                            <div class="mb-4">
                                                <input type="email" :class="`${validation.email ? 'input-required' : ''}`" name="full_email" placeholder="Enter Company Email" v-model="corporateData.email">
                                            </div>
                                            <div class="text-center" :class="{ dnone: !forCommercialUse }">
                                                <button v-if="!loading" class="btn yellow-btn submit px-4 mb-2" @click="corporateDownload(music.id,'long')">DOWNLOAD</button>
                                                <div v-if="loading" class="text-center"> <img src="/images/mini-loader.gif" style="max-width:52px"> Downloading... </div>
                                                <p class="note mb-4 py-2">By clicking Download, you agree to our <a href="/license-terms" target="_blank" class="uline">License Terms</a>.</p>
                                            </div>
                                        </div>
                                        
                                        <div class="text-center" :class="{ dnone: forCommercialUse }">
                                            <button v-if="!loading" class="btn yellow-btn submit px-4 mb-2" @click="directDownloadLong(music.id)">DOWNLOAD</button>
                                            <div v-if="loading" class="text-center"> <img src="/images/mini-loader.gif" style="max-width:52px"> Downloading...</div>
                                            <p class="note mb-4 py-2">By clicking Download, you agree to our <a href="/license-terms" target="_blank" class="uline">License Terms</a>.</p>
                                        </div>
                                    </div>
                                    <div class="px-md-4 pt-1" v-else>
                                        <div class="text-center":class="{ dnone: hideDisabledDownlad }">
                                            <button class="btn yellow-btn submit px-4 mb-2 disabled" type="submit" disabled>DOWNLOAD</button>
                                            <p class="note mb-4 py-2">By clicking Download, you agree to our <a href="/license-terms" target="_blank" class="uline">License Terms</a>.</p>
                                        </div>

                                        <div class="modal-form" :class="{ dnone: forPersonalUse }">
                                            <div class="mb-3">
                                                <input type="text" :class="`${validation.fullName ? 'input-required' : ''}`" name="full_name" placeholder="Enter Full Name" v-model="corporateData.fullName">
                                            </div>
                                            <div class="mb-3">
                                                <input type="text" :class="`${validation.company ? 'input-required' : ''}`" name="company_name" placeholder="Enter Company Name" v-model="corporateData.company">
                                            </div>
                                            <div class="mb-4">
                                                <input type="email" :class="`${validation.email ? 'input-required' : ''}`" name="full_email" placeholder="Enter Company Email" v-model="corporateData.email">
                                            </div>
                                            <div class="text-center">
                                                <button v-if="!loading" :class="{ dnone: !forCommercialUse }" class="btn yellow-btn submit px-4 mb-2" @click="corporateDownload(music.id,'short')">DOWNLOAD</button>
                                                <div v-if="loading" class="text-center"> <img src="/images/mini-loader.gif" style="max-width:52px"> Downloading...</div>
                                                <p class="note mb-4 py-2">By clicking Download, you agree to our <a href="/license-terms" target="_blank" class="uline">License Terms</a>.</p>
                                            </div>
                                        </div>
                                        
                                        <div class="text-center" :class="{ dnone: forCommercialUse }">
                                            <button v-if="!loading" class="btn yellow-btn submit px-4 mb-2" @click="directDownload(music.id)">
                                              DOWNLOAD
                                            </button>
                                            <div v-if="loading" class="text-center"> <img src="/images/mini-loader.gif" style="max-width:52px"> Downloading...</div>
                                            <p class="note mb-4 py-2">By clicking Download, you agree to our <a href="/license-terms" target="_blank" class="uline">License Terms</a>.</p>


                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" v-if="this.isSuccessDownload">
            <div class="container">
                <div class="body">
                    <div class="text-right">
                        <a @click="closeModal()" class="close-modal mb-0">
                            <x-icon size="1.5x" class="custom-class"></x-icon>
                            <span class="sr-only">Close</span>
                        </a>
                    </div>
                    <div class="text-center">
                        <div class="px-4">
                            <h4 class="mb-2">Thanks for downloading!</h4>
                            <p class="mb-4" v-if="!forCommercialUse && !thanksOnly">The audio file should download automatically.</p>
                            <p class="mb-4" v-if="forCommercialUse && !thanksOnly">The download link has been emailed to you</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SubFooter from "../partials/SubFooter.vue";
import { ArrowLeftIcon } from 'vue-feather-icons'
import { ArrowRightIcon } from 'vue-feather-icons'
import { CopyIcon } from 'vue-feather-icons'
import { CheckIcon } from 'vue-feather-icons'
import { XIcon } from 'vue-feather-icons'
import constant from "../../constants/constant";
import {mapActions} from "vuex";
import axios from "axios";

export default {
    components: {
        SubFooter,
        ArrowLeftIcon,
        ArrowRightIcon,
        CopyIcon,
        CheckIcon,
        XIcon
    },
    data() {
        return {
            music: [],
            audioSrc: '',
            audioSrcLong: '',
            title:'',
            isModalHidden: true,
            forPersonalUse: true,
            forCommercialUse: true,
            hideDisabledDownlad: false,
            use: null,
            attrLink: 'Music by Viberary.com',
            copyText: 'Copy',
            trackType: null,
            isSuccessDownload: false,
            corporateData: {
                fullName: '',
                company: '',
                email: '',
            },
            main_url:'',
            content:'',
            thanksOnly:false,
            validation:{
              email:false,
              company:false,
              fullName:false
            },
            wavesurfer:null,
            loading:false,
        }
    },
    methods: {
        ...mapActions({
          downloadSong:'download/downloadSong',
          confirmDownloadSong:'download/confirmDownloadSong',
          getContent:'content/getContent',
        }),
        async getData(){
          let payload = {
            url:this.main_url + '/api/content/download',
          };
          const res = await this.getContent(payload);
          if(res.status == 200){
            this.content = res.data.content;
          }
        },
        timeFormat(flt){
          var min = parseInt(flt/60),
              sec = parseInt(flt%60) < 10 ? "0"+parseInt(flt%60) : parseInt(flt%60).toString();
          return min+":"+sec;
        },
        async directDownload(id,thanksOnly=false) {


            let self = this;
            self.loading = true;
            axios({
                url: this.audioSrc,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', "Viberary - " + this.title+' - short.mp3');
                    document.body.appendChild(fileLink);

                    fileLink.click();

                    if(response.status == 200) {
                        this.isModalHidden = true;
                        this.isSuccessDownload = true;
                        this.thanksOnly = thanksOnly;
                        self.loading = false;
                        axios({
                            url: this.main_url + '/api/downloaded/'+id,
                            method: 'POST',
                        }).then((res) => {
                            //console.log(res.status)
                        });
                    }
            });
        },
        async directDownloadLong(id,thanksOnly = false) {
            let self = this;
            self.loading = true;
            axios({
                url: this.audioSrcLong,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', "Viberary - " +this.title+' - long.mp3');
                    document.body.appendChild(fileLink);

                    fileLink.click();

                    if(response.status == 200) {
                        this.isModalHidden = true;
                        this.isSuccessDownload = true;
                        this.thanksOnly = thanksOnly;
                        self.loading = false;

                        axios({
                            url: this.main_url + '/api/downloaded/'+id,
                            method: 'POST',
                        }).then((res) => {
                            //console.log(res.status)
                        });
                    }
            });
        },
        createKey(length) {
          var result           = [];
          var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          var charactersLength = characters.length;
          for ( var i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() *
                charactersLength)));
          }
          return result.join('');
        },
        async corporateDownload(id,version) {
            this.loading = true;

            if(this.corporateData.fullName.trim() == ''){
                this.validation.fullName = true;
            }else{
              this.validation.fullName = false;
            }

            if(this.corporateData.company.trim() == '' ){
              this.validation.company = true;
            }else{
              this.validation.company = false;
            }

            if(this.corporateData.email.trim() == '' ){
              this.validation.email = true;
            }else{
              this.validation.email = false;
            }


          if(this.corporateData.fullName.trim() != '' &&
              this.corporateData.company.trim() != '' &&
              this.corporateData.email.trim() != ''){
            let code = this.createKey(40);
            let main_link = location.protocol + '//' + location.host + location.pathname
            let payload = {
              id:id,
              corporateData:this.corporateData.fullName,
              company:this.corporateData.company,
              email:this.corporateData.email,
              url:this.main_url + '/api/download_song',
              attribution: main_link,
              download_link: main_link +'?download=1&&key=' + code,
              code:code,
              version:version,
            };
            let result = await this.downloadSong(payload);
            if(result.status){
              this.isModalHidden = true;
              this.isSuccessDownload = true;
              this.loading = false;
            }else{
              this.loading = false;
            }
            this.validation.fullName = false;
            this.validation.company = false;
            this.validation.email = false;
          }else{
            this.loading = false;
          }
        },
        showModal(type) {
          this.forCommercialUse = true;
          this.forPersonalUse = true;
          this.trackType = type;
          this.isModalHidden = false;
        },
        closeModal() {
            this.isModalHidden = true
            this.isSuccessDownload = false
            this.hideDisabledDownlad = false
            this.thanksOnly = false;
            this.use = null
        },
        forCommercial() {
            this.forCommercialUse = true
            this.forPersonalUse = false
            this.hideDisabledDownlad = true
        },
        forPersonal() {
            this.forPersonalUse = true
            this.forCommercialUse = false
            this.hideDisabledDownlad = true
        },
        onCopy: function (e) {
            //alert('You just copied the following text to the clipboard: ' + e.text)
            this.copyText = 'Copied'
            //console.log(e);
        },
        onError: function (e) {
            //alert('Failed to copy the text to the clipboard')
            console.log(e);
        },
    },
    beforeDestroy() {
      this.wavesurfer.destroy();
    },
  async created() {
        this.main_url = constant.MAIN_URL;
        let self = this;
        this.main_url = constant.MAIN_URL;
        const slug = this.$route.params.slug;
        await this.getData();
        axios
        .get(`${this.main_url}/api/music/${slug}`)
        .then( async (response) => {
            //console.log(response.data)
            this.music = response.data
            document.getElementById("endTime").textContent = self.timeFormat(this.music ? this.music.duration : 0);
            document.getElementById("direct_download").textContent = self.timeFormat(this.music ? this.music.duration : 0);
            document.getElementById("direct_download_long").textContent = self.timeFormat(this.music ? this.music.durationLong : 0);
            this.audioSrc = this.main_url + `/tracks/${this.music.shortTrack}`
            this.audioSrcLong = this.main_url + `/tracks/${this.music.longTrack}`
            this.title = this.music.title
            this.attrLink = `Music by Viberary.com: https://www.viberary.com/download/${this.music.slug}`

              //download from link
              let params = self.$route.query;
              if(params.download){
                self.loading = true;
                let payload = {
                  url:self.main_url + '/api/confirm_download',
                  key:params.key
                };
                axios({
                  url: payload.url,
                  method: 'POST',
                  data: payload
                }).then((response) => {
                  if(response.status == 200 ){
                    if(response.data.status && response.data != ""){
                      if(response.data.version == 'short') {
                        self.directDownload(response.data.music_id,true);
                      }else{
                        self.directDownloadLong(response.data.music_id,true)
                      }
                    }else{
                      this.loading = false;
                    }
                  }return {status:false}
                }) .catch(error => {
                  return {status:false,error:error}
                });
              }

              //end download

            this.$loadScript("/js/wavesurfer.js")
            .then(() => {
                //console.log("wavesurfer loaded..");
                let waveform = document.getElementById('waveform');
                var wavesurfer = WaveSurfer.create({
                    container: waveform,
                    barWidth: 2,
                    barHeight: 1.6,
                    barGap: null,
                    waveColor: '#ebebeb',
                    progressColor: '#ffd300',
                    cursorColor: '#fff0',
                    height: 60,
                    pixelRatio: 1,
                    responsive: true,
                });

            let mainPlayer = document.getElementById("mainPLayer");

            function timeFormat(flt){
                var min = parseInt(flt/60),
                    sec = parseInt(flt%60) < 10 ? "0"+parseInt(flt%60) : parseInt(flt%60).toString();
                return min+":"+sec;
            }
            wavesurfer.load(this.audioSrc,JSON.parse(this.music.peaks));

            wavesurfer.on("ready",function(){
                document.getElementById("endTime").textContent = timeFormat(wavesurfer.getDuration());
                document.getElementById("direct_download").textContent = timeFormat(wavesurfer.getDuration());
            });
            this.wavesurfer = wavesurfer;
            // Play control
            mainPlayer.addEventListener("click",function(e){
                e.preventDefault();
                this.classList.toggle("playing");
                wavesurfer.playPause();
            });

                // waveform interraction
            var playbackTimeout;
            function refreshPie(thisPie, wavesurfObj){
                setTimeout(function(){
                    var curTime = wavesurfObj.getCurrentTime(),
                        endTime = wavesurfObj.getDuration(),
                        spinnerAngle = (curTime/endTime)*360;
                    // console.log(curTime + " vs "+ endTime);
                    thisPie.querySelector(".spinner").style.transform = "rotate("+spinnerAngle+"deg)";
                    if(spinnerAngle > 180){
                        thisPie.querySelector(".timer").classList.remove("first-half");
                    }else{
                        thisPie.querySelector(".timer").classList.add("first-half");
                    }
                    // document.getElementById("seekTime").textContent = timeFormat(curTime+0.3); //intervals at .3 sec
                },300);
            }

            wavesurfer.on('audioprocess', function() {
              if(wavesurfer.isPlaying()) {
                let totalTime = wavesurfer.getDuration();
                let currentTime = wavesurfer.getCurrentTime();
                let remainingTime = totalTime - currentTime;
                document.getElementById('seekTime').textContent = timeFormat(currentTime);
                document.getElementById('endTime').textContent = timeFormat(remainingTime);
              }
            });

                wavesurfer.on("play", function(){
                    // console.log("play");
                    refreshPie(mainPlayer, wavesurfer);
                    mainPlayer.classList.add("playing");
                    clearInterval(playbackTimeout);
                    playbackTimeout = setInterval(function(){
                        refreshPie(mainPlayer, wavesurfer);
                    },500);
                });
                wavesurfer.on("finish", function(){
                    // console.log("finished");
                    clearInterval(playbackTimeout);
                    mainPlayer.classList.remove("playing");
                });

                // Stop when click back
                document.getElementById('back_btn').addEventListener("click",function(e){
                    wavesurfer.stop();
                });
            })
            .catch(() => {
            // Failed to fetch script
            });        
        })
        .catch(error => {
            console.log(error)
        })
        //.finally(() => this.loading = false)
    },
}
</script>

<style>
.input-required{
  border: 1px solid #e81313 !important;
}
</style>