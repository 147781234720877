<template>
  <section id="main" class="main-viewport">
    <div class="container-fluid content-wrap p-0">
      <!-- Content start -->
      <div class="row no-gutters flex-no-wrap">
        <div class="d-lg-flex col-auto sbar-w">
          <Sidebar :slug="slug" :type="type"/>
        </div>
        <div class="col-12 col-lg-auto flex-grow-1">
          <div class="content-wrap-inner flex-wrap d-flex flex-column justify-content-between">
            <div class="vbry-playlist vinyl-grp-wrap" id="vinyl_record">
              <div class="playlist-wrap">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="album-cover">
                      <div class="title-label">
                        <span>{{songType ? songType : 'DISCOVER'}}</span>
                      </div>
                      <div class="title">
                        <h1>{{categoryName ? categoryName : 'Hot Picks'}}</h1>
                      </div>
                      <div class="meta">
                        <a :class="`play-all ${(loadedSongs != (songs ? songs.length : 0)) || loadedSongs == 0 ? 'disabled' : ''}`" id="play_all" @click.prevent="playAll">
                          PLAY THIS PAGE <ion-icon name="play-sharp"></ion-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="vinyl-crop">
                      <div class="vinyl-grp">
                        <div class="vinyl-img">
                          <img
                            src="/images/disk.png"
                            width="320"
                            height="320"
                            alt="album cover"
                            loading="lazy"
                            draggable="false"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="play-table">
                  <div class="heading d-flex">
                    <div class="title-col px-1">
                      <span>TITLE</span>
                    </div>
                    <div class="duration-col px-1">
                      <span>DURATION</span>
                    </div>
                    <div class="tempo-col px-1">
                      <span>TEMPO</span>
                    </div>
                  </div>
                  <div class="queue">
                    <div
                      v-for="song in songs"
                      v-bind:key="song.id"
                      class="song-row d-flex" :id="song.slug"
                    >
                      <audio
                        :id="song.id"
                        :src="`${MAIN_URL + '/tracks/' + song.shortTrack}`"
                        class="audio"
                      ></audio>

                      <div class="play-col px-1 my-auto">
                        <a v-if="song.isReady" :class="`pause-play mx-lg-2 ${selectedSong && selectedSong.id == song.id && isPlaying ? 'playing' : ''}`" @click.prevent="selectSong(song)">
                          <div class="pie-timer">
                            <div class="timer first-half">
                              <div class="left-mask"></div>
                              <div class="right-half"></div>
                              <div class="left-half spinner"></div>
                            </div>
                            <div class="disk"></div>
                          </div>
                          <ion-icon name="play-sharp"></ion-icon>
                          <ion-icon name="pause-sharp"></ion-icon>
                        </a>
                        <div v-if="!song.isReady" class="text-center"> <img src="/images/mini-loader.gif" style="max-width:52px"> </div>
                      </div>
                      <div class="title-col px-1 my-auto">
                        <a @click.prevent="downloadSong(song)" class="title">
                          <span>{{ song.title }}</span>
                          <span v-for="(mood, j) in song.mood" :key="j+'title'"
                            class="title-label d-lg-none">
                            {{ mood.categoryName }},
                            </span>
                        </a>
                      </div>
                      <div class="duration-col px-1 my-auto">
                        <span>
                          {{timeFormat(song.duration)}}
                        </span>
                      </div>
                      <div class="tempo-col px-1 my-auto">
                        <span>{{ song.tempo }} bpm</span>
                      </div>
                      <div class="preview-col flex-grow-1 my-auto mx-lg-4">
                        <div class="waveform"></div>
                      </div>
                      <div class="download-col my-auto mx-lg-2">
                        <a @click.prevent="downloadSong(song)" class="dl-link" id="download_btn">
                          <span class="sr-only">Download</span>
                          <download-icon size="1.5x" class="custom-class"></download-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="pagination my-4 py-md-4">
                <ul class="d-flex justify-content-center mx-auto my-2 pb-4">
                  <li v-if="pagination.prev_page_url" @click.prevent="getSongsOfPage(pagination.prev_page_url)" class="mx-1 mx-sm-2 mx-md-3" >
                    <a
                      v-scroll-to="'#vinyl_record'"
                      href="#"
                      class="prev scroll"
                    >
                      <chevron-left-icon size="1.5x" class="custom-class"></chevron-left-icon>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>
                  <li class="mx-1 mx-sm-2 mx-md-3" v-for="(link,p) in pagination.links" :key="p+'-pagination'">
                    <a 
                      v-scroll-to="'#vinyl_record'"
                      href="#" 
                      v-if="Number.isInteger(parseInt(link.label))" 
                      @click.prevent="getSongsOfPage(link.url)"
                      class="scroll"
                      :class="`${pagination.current_page == link.label ? 'active' : ''}`"
                    >
                      {{link.label}}
                    </a>
                  </li>
                  <li v-if="pagination.next_page_url" class="mx-1 mx-sm-2 mx-md-3">
                    <a
                      v-scroll-to="'#vinyl_record'"
                      href="#" 
                      @click.prevent="getSongsOfPage(pagination.next_page_url)" 
                      class="next scroll"
                    >
                      <chevron-right-icon size="1.5x" class="custom-class"></chevron-right-icon>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <!-- Content end -->
    </div>
  </section>
</template>

<script>
import Sidebar from "../partials/Sidebar.vue";
import Footer from "../partials/Footer.vue";
import constant from "../../constants/constant";

import { DownloadIcon } from 'vue-feather-icons';
import { ChevronLeftIcon } from 'vue-feather-icons';
import { ChevronRightIcon } from 'vue-feather-icons';
import {mapGetters, mapActions} from 'vuex';
import MinimapPlugin from '../../../public/js/wavesurfer.minimap'


export default {
    props:['type','slug'],
    components: {
      Footer,
      Sidebar,
      DownloadIcon,
      ChevronLeftIcon,
      ChevronRightIcon,
    },
    data() {
      return {
        playlistSongLabel: 'Song',
        minimap:null,
        initialPlay:true,
        MAIN_URL: '',
        idTime:[],
      };
    },
    async created() {
      if(this.selectedSong && this.selectedSong.wavesurfer){
        this.selectedSong.wavesurfer.stop();
        this.selectedSong.wavesurfer.destroyAllPlugins();
      }
    },
    beforeDestroy() {
      this.$store.commit("player/SET_SHOW_STICKY_PLAYER" , false);
      this.$store.commit("player/SET_SELECTED_SONG" , null);
      this.$store.commit("player/SET_IS_PLAYING" , false);
    },
  async mounted(){
      this.MAIN_URL = constant.MAIN_URL;
      this.$store.commit("player/SET_LOADED_SONGS" , 0)
      if(!this.slug){
        await this.getSongs();
      }
      await this.loadScript();
      await this.preparePlayers();
      this.$store.commit("player/SET_IS_LOADING" , false);
      if(this.$route.name === 'HomeSearch' || this.$route.name === 'HomeMusic'){
        let link = document.getElementsByClassName('scroll');
        setTimeout( () => {
          link[0].click();
        }, 2000);
      }
    },
    methods: {
      ...mapActions({
        fetchSongs:'player/fetchSongs',
      }),
      async getSongsOfPage(url){
        if(!((this.loadedSongs != (this.songs ? this.songs.length : 0)) || this.loadedSongs == 0)){
          this.$store.commit("player/SET_LOADED_SONGS" , 0)
          if(this.selectedSong && this.selectedSong.wavesurfer){
            this.selectedSong.wavesurfer.stop();
            this.selectedSong.wavesurfer.destroyAllPlugins();
          }
          document.getElementById('vinyl_record').classList.add("animate-paused");
          this.$store.commit("player/SET_IS_PLAYING" , false);
          this.$store.commit("player/SET_SHOW_STICKY_PLAYER" , false);
          this.$store.commit("player/SET_SELECTED_SONG" , null);
          let self = this;
          await self.fetchSongs({url:url});
          await self.preparePlayers();
        }
      },
      async getSongs(){
        let result = await this.fetchSongs({url:this.MAIN_URL + '/api/playlist'});
        if(result.status){
          let songCount = this.songs ? this.songs.length : 0;
          if(songCount > 1) {
            this.playlistSongLabel = 'Songs'
          }
        }else{
          console.log(result.error);
        }
      },
      async loadScript(){
        return this.$loadScript("/js/wavesurfer.js").then(() => {
          return {status:true}
        }).catch((e) => {
          console.log(e);
          return {status:false}
        });
      },
      async preparePlayers(){
        let self = this;
        return new Promise((resolve) => {
          let newsongs = [];
          document.querySelectorAll(".song-row").forEach((song) => {
            //slug of song
            let song_slug = song['id'];
            let audio = song.querySelector(".audio");
            let src = audio["src"];
            let waveform = song.querySelector(".waveform");
            let wavesurfer = WaveSurfer.create({
              container: waveform,
              barWidth: 2,
              barHeight: 1.6,
              barGap: null,
              waveColor: "#ebebeb",
              progressColor: "#ffd300",
              cursorColor: "#fff0",
              height: 34,
              pixelRatio: 1,
              responsive: true,
              backend: 'MediaElement'
            });

            self.songs.map((song) => {
              if(song_slug == song.slug){
                if(song.peaks){
                  wavesurfer.load(src,JSON.parse(song.peaks));
                  // wavesurfer.backend.setPeaks(JSON.parse(song.peaks));
                }else{
                  wavesurfer.load(src);
                }

                //for duration maybe optimize by saving it on create of music on admin
                wavesurfer.on('ready', function () {
                  self.$store.commit("player/SET_LOADED_SONGS" , self.loadedSongs + 1)
                  song.isReady = true;
                  self.$store.commit("player/UPDATE_SONGS_TIME" , song);
                });

                song.wavesurfer =  wavesurfer;
                newsongs.push(song);
              }
            });
          });
          self.$store.commit("player/SET_SONGS" , newsongs);
          resolve(true);
        });
      },
      selectSong(song){
        this.$store.commit("player/SET_SELECTED_SONG" , song);
        this.playSong(song);
      },
      playSong(song){
        if(song && song.wavesurfer){
          let self = this;
          this.songs.map(s => {
            if(s.id !== song.id && s.wavesurfer){
              s.wavesurfer.destroyAllPlugins();
              s.wavesurfer.pause();
            }
          });
          document.getElementById('vinyl_record').classList.add("animate-paused");
          song.wavesurfer.playPause();
          if(song.wavesurfer.isPlaying()){
            // vinyl record animation
            document.getElementById('vinyl_record').classList.add("playing");
            document.getElementById('vinyl_record').classList.remove("animate-paused");

            //show sticky player
            this.$store.commit("player/SET_SHOW_STICKY_PLAYER" , true);

            //minimap
            song.wavesurfer.destroyAllPlugins();
            song.wavesurfer.addPlugin(
                // Minimap
                MinimapPlugin.create({
                  container:minimap,
                  barWidth: 2,
                  barHeight: 1.6,
                  barGap: null,
                  waveColor: "#ebebeb",
                  progressColor: "#ffd300",
                  cursorColor: "#fff0",
                  height: 34,
                  pixelRatio: 1,
                  responsive: true,
                })
            ).initPlugin('minimap');
            //end minimap

            //set timer
            this.moveTime(song.wavesurfer);

            //set volume
            if(this.initialPlay){
              song.wavesurfer.setVolume(1);
              this.$store.commit("player/SET_VOLUME" , 1);
            }else{
              song.wavesurfer.setVolume(this.currentVolume);
              this.$store.commit("player/SET_VOLUME" , this.currentVolume);
            }

            document.querySelector('#volume').value = song.wavesurfer.getVolume();
            var volumeInput = document.querySelector('#volume');
            var onChangeVolume = function (e) {
              song.wavesurfer.setVolume(e.target.value);
              self.$store.commit("player/SET_VOLUME" , song.wavesurfer.getVolume());
            };
            volumeInput.addEventListener('input', onChangeVolume);
            volumeInput.addEventListener('change', onChangeVolume);

            //add on finish song
            song.wavesurfer.on('finish', function () {
              if(song.id == self.songs[self.songs.length -1].id){
                self.$store.commit("player/SET_IS_PLAYING" , false);
                self.$store.commit("player/SET_SHOW_STICKY_PLAYER" , false);
              }else{
                self.next();
              }

            });
            this.$store.commit("player/SET_IS_PLAYING" , true);
          }else{
            this.$store.commit("player/SET_IS_PLAYING" , false);
            this.$store.commit("player/SET_SHOW_STICKY_PLAYER" , false);
          }
          this.initialPlay = false;
        }
      },
      next(){
        let songIndex = null;
        if(this.selectedSong){
          let length = this.songs.length;
          for (let ctr = 0; ctr < length; ctr++){
            if(this.songs[ctr].id == this.selectedSong.id && (length-1) != ctr){
              songIndex = ctr + 1;
              break;
            }
          }
          if(songIndex){
            this.selectSong(this.songs[songIndex]);
          }
        }
      },
      timeFormat(flt){
        var min = parseInt(flt/60),
            sec = parseInt(flt%60) < 10 ? "0"+parseInt(flt%60) : parseInt(flt%60).toString();
        return min+":"+sec;
      },
      timeFormatWords(flt){
        var min = parseInt(flt/60),
            sec = parseInt(flt%60) < 10 ? "0"+parseInt(flt%60) : parseInt(flt%60).toString();
        return min+" min "+sec+" sec";
      },
      moveTime(wavesurfer){
        let mainPlayer = document.getElementById("mainPLayer");
         let self = this;
        wavesurfer.on('audioprocess', function() {
          if(wavesurfer && wavesurfer.isPlaying()) {
            let totalTime = wavesurfer.getDuration();
            let currentTime = wavesurfer.getCurrentTime();
            let remainingTime = totalTime - currentTime;
            let spinnerAngle = (currentTime/totalTime)*360;

            mainPlayer.querySelector(".spinner").style.transform = "rotate("+spinnerAngle+"deg)";
            if(spinnerAngle > 180){
              mainPlayer.querySelector(".timer").classList.remove("first-half");
            }else{
              mainPlayer.querySelector(".timer").classList.add("first-half");
            }

            document.getElementById('seekTime').textContent = self.timeFormat(currentTime);
            document.getElementById('endTime').textContent = self.timeFormat(remainingTime);
          }
        });
      },
      playAll(){
        if(this.songs && this.loadedSongs == this.songs.length){
          if(this.selectedSong){
            this.selectSong(this.selectedSong);
          }else if(this.songs){
            this.selectSong(this.songs[0]);
          }
        }
      },
      downloadSong(song){
        if(song){
          if(song.wavesurfer && song.wavesurfer.isPlaying()){
            song.wavesurfer.pause();
          }
          this.$router.push({ path: "/download/" + song.slug})
        }
      },
    },
    computed : {
    ...mapGetters({
        songs : 'player/getSongs',
        selectedSong : 'player/getSelectedSong',
        currentVolume : 'player/getVolume',
        isPlaying:'player/getIsPlaying',
        pagination:'player/getPagination',
        totalDuration:'player/getTotalTime',
        loadedSongs:'player/getLoadedSongs',
        categoryName:'player/getCategoryName',
        categorySlug:'player/getCategorySlug',
        isSpecial:'player/getIsSpecial',
        songType:'player/getType',
      }),
    }
};
</script>