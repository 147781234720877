<template>
    <div class="vbry-filters position-sticky w-100">
        <div class="filter-lbl">
            <i data-feather="sliders"></i>
            Filter By
        </div>
        <div class="accordions">
            <div class="accordion" :class="{ active: moodIsActive }" @click="showMoods()">
                <div class="accordion-trigger">
                    <span>Mood</span>
                </div>
                <div class="accordion-content">
                    <ul class="menu-list">
                        <li v-for="mood in moods" :key="mood.id">
                            <a :class="`${categoryName ? categoryName.toLowerCase() == mood.categoryName.toLowerCase() ? 'active' : '' : ''}`" @click.prevent="filterSongs(mood.categorySlug,'mood')"><span>{{ mood.categoryName }}</span></a>
<!--                             <a href="#!">-->
<!--                                <router-link :to="`/mood/${mood.categorySlug}`"><span>{{ mood.categoryName }}</span> </router-link>-->
<!--                             </a>-->
                        </li>
                    </ul>
                </div>
            </div>
            <div class="accordion" :class="{ active: genreIsActive }" @click="showGenres()">
                <div class="accordion-trigger">
                    <span>Genre</span>
                </div>
                <div class="accordion-content">
                    <ul class="menu-list">
                        <li v-for="genre in genres" :key="genre.id">
                          <a :class="`${categoryName ? categoryName.toLowerCase() == genre.genreName.toLowerCase() ? 'active' : '' : ''}`" @click.prevent="filterSongs(genre.genreSlug,'genre')"><span>{{ genre.genreName }}</span></a>
<!--                            <a @click.prevent="getSongs(genre.genreSlug,'genre')" href="#"><span>{{ genre.genreName }}</span></a>-->
                        </li>
                    </ul>
                </div>
            </div>
            <div class="accordion" :class="{ active: tempoIsActive }" @click="showTempo($event)">
                <div class="accordion-trigger">
                    <span>Tempo</span>
                </div>
                <div class="accordion-content">
                    <div class="px-4">

                      <div class="tempo-filter">
                        <div id="slider" ref="slider"></div>
                        <input v-model="minRange" v-on:change="updateSlider" style="display: none" />
                        <input v-model="maxRange" v-on:change="updateSlider" style="display: none" />
                        <small class="d-block" id="tempovalue">{{minRange}} bpm - {{maxRange}} bpm</small>
                      </div>

                    </div>
                </div>
            </div>
            <div class="accordion" v-for="special in special_moods" :key="special.id">
              <a @click.prevent="filterSongs(special.categorySlug,'mood')" class="link"><span>{{ special.categoryName }}</span></a>

<!--                <a @click.prevent="getSongs(special.categorySlug,'mood')" href="#" class="link">-->
<!--                    <span>{{ special.categoryName }}</span>-->
<!--                </a>-->
            </div>
        </div>
    </div>
</template>

<script>
import constant from "../../constants/constant";
import {mapActions, mapGetters} from "vuex";


import * as noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';




export default {
    props:['slug','type'],
    data() {
        return {
            value: [20, 120],
            // moods: [],
            // genres: [],
            // special_moods: [],
            tempos: [],
            moodIsActive: false,
            genreIsActive: false,
            tempoIsActive: false,
            MAIN_URL: '',
            minRange: null,
            maxRange: null,
            slider: {
              startMin: 20,
              startMax: 120,
              min: 20,
              max: 120,
              start: 20,
              step: 1
            },
            myEfficientFn:null,
            loading:true
        }
    },
    methods: {
        ...mapActions({
          fetchSongs:'player/fetchSongs',
          fetchSpecialMoods:'player/fetchSpecialMoods',
          fetchGenre:'player/fetchGenre',
          fetchMoods:'player/fetchMoods',
          fetchTempoLimits:'player/fetchTempoLimits'
        }),
        updateSlider: function updateSlider() {
          this.$refs.slider.noUiSlider.set([this.minRange, this.maxRange]);
        },
        showMoods() {
            if(this.moodIsActive == true) {
              this.moodIsActive = false;
            } else {
              this.tempoIsActive = false;
              this.genreIsActive = false;
              this.moodIsActive = true;
            }
        },
        showGenres() {
            if(this.genreIsActive == true) {
              this.genreIsActive = false;
            } else {
              this.genreIsActive = true;
              this.moodIsActive = false;
              this.tempoIsActive = false;
            }
        },
        showTempo(event) {
          if(event.target.className == "accordion-trigger" || event.target.className == ''){
            if(this.tempoIsActive == true) {
              this.tempoIsActive = false;
            } else {
              this.tempoIsActive = true;
              this.genreIsActive = false;
              this.moodIsActive = false;
            }
          }
        },
        filterSongs(slug,mood){
          event.stopPropagation();
          if(document.getElementById('mobile-close-button')){
            document.getElementById('mobile-close-button').click();
          }

          // this.$router.push({ path: `/${mood}/${slug}`});
          history.pushState(null, null, `/${mood}/${slug}`);
          this.getSongs(slug,mood);
        },
        async getSongs(slug,type){
          this.$store.commit("player/SET_SONGS" , []);
          this.$store.commit("player/SET_LOADED_SONGS" , 0)
          if(this.selectedSong && this.selectedSong.wavesurfer){
            this.selectedSong.wavesurfer.stop();
            this.selectedSong.wavesurfer.destroyAllPlugins();
          }
          document.getElementById('vinyl_record').classList.add("animate-paused");
          this.$store.commit("player/SET_IS_PLAYING" , false);
          this.$store.commit("player/SET_SHOW_STICKY_PLAYER" , false);
          this.$store.commit("player/SET_SELECTED_SONG" , null);
          let self = this;
          await this.fetchSongs({url:this.MAIN_URL + "/api/"+type+"/" +slug});
          await self.preparePlayers();
        },
        async preparePlayers(){
          let self = this;
          return new Promise((resolve) => {
            let newsongs = [];
            document.querySelectorAll(".song-row").forEach((song) => {
              //slug of song
              let song_slug = song['id'];
              let audio = song.querySelector(".audio");
              let src = audio["src"];
              let waveform = song.querySelector(".waveform");
              if(waveform.children.length == 0){
                let wavesurfer = WaveSurfer.create({
                  container: waveform,
                  barWidth: 2,
                  barHeight: 1.6,
                  barGap: null,
                  waveColor: "#ebebeb",
                  progressColor: "#ffd300",
                  cursorColor: "#fff0",
                  height: 34,
                  pixelRatio: 1,
                  responsive: true,
                  backend: 'MediaElement'
                });

                self.songs.map((song) => {
                  if(song_slug == song.slug){
                    if(song.peaks){
                      wavesurfer.load(src,JSON.parse(song.peaks));
                      // wavesurfer.backend.setPeaks(JSON.parse(song.peaks));
                    }else{
                      wavesurfer.load(src);
                    }

                    //for duration maybe optimize by saving it on create of music on admin
                    wavesurfer.on('ready', function () {
                      self.$store.commit("player/SET_LOADED_SONGS" , self.loadedSongs + 1)
                      song.isReady = true;
                      self.$store.commit("player/UPDATE_SONGS_TIME" , song);
                    });
                    song.wavesurfer =  wavesurfer;
                    newsongs.push(song);
                  }
                });
              }
            });
            self.$store.commit("player/SET_SONGS" , newsongs);
            resolve(true);
          });
        },
        async fetchTempo(){
          await this.getSongs(JSON.stringify([this.minRange,this.maxRange]),'tempo');
        },
      debounce(func, wait, immediate) {
        var timeout;
        return function() {
          var context = this, args = arguments;
          var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
          };
          var callNow = immediate && !timeout;
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
          if (callNow) func.apply(context, args);
        };
      }

},
  async mounted() {
    await this.fetchTempoLimits();
    let low = parseInt(this.tempoLimits.low);
    let high = parseInt(this.tempoLimits.high);
    this.slider = {
      startMin: low,
      startMax: high,
      min: low,
      max: high,
      start: low,
      step: 1
    };
    this.MAIN_URL = constant.MAIN_URL;
    noUiSlider.create(this.$refs.slider, {
      start: [this.slider.startMin, this.slider.startMax],
      step: this.slider.step,
      range: {
        'min': this.slider.min,
        'max': this.slider.max
      }
    });
    this.$refs.slider.noUiSlider.on('update',(values, handle) => {
      this[handle ? 'maxRange' : 'minRange'] = parseInt(values[handle]);
    });

    let self = this;
    this.myEfficientFn = this.debounce(function() {
      self.getSongs(JSON.stringify([self.minRange,self.maxRange]),'tempo');
    }, 300);
  },
  async created() {
    await this.fetchMoods();
    await this.fetchGenre();
    await this.fetchSpecialMoods();
    if(this.slug){
      this.genreIsActive = this.type == "genre";
      this.moodIsActive = this.type == "mood";
      await this.getSongs(this.slug,this.type);
    }
    this.loading = false;
  },
  computed : {
    ...mapGetters({
      selectedSong : 'player/getSelectedSong',
      songs : 'player/getSongs',
      loadedSongs:'player/getLoadedSongs',
      moods:'player/getMoods',
      genres:'player/getGenre',
      special_moods:'player/getSpecialMoods',
      songType:'player/getType',
      categoryName:'player/getCategoryName',
      tempoLimits:'player/getTempoLimit'
    }),
  },
  watch: {
    minRange: function () {
      if(!this.loading){
        console.log('minRange was changed');
        this.myEfficientFn();
      }

    },
    maxRange: function () {
      if(!this.loading){
        console.log('maxRange was changed');
        this.myEfficientFn();
      }
    }
  }
}
</script>